.timer {
  padding: 10px;
  background-color: $colorRed;
  @include media-breakpoint-up(lg){
    padding: 20px 80px;
  }
}

.timer__wrapper {
  position: relative;
  max-width: 960px;
  margin: 0 auto;

  background-color: #ffffff;

  @include media-breakpoint-up(md) {
    padding-right: 260px;
  }

  @media (min-width: 1920px){
    max-width: 1160px;
  }
}

.timer__list{
  display: flex;
  width: 100%;
  max-width: 800px;
  padding: 15px 20px;

  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-up(sm){
    justify-content: space-around;
    padding: 20px;
    justify-content: space-between;
  }

  @include media-breakpoint-up(md){
    position: relative;

    &::after{
      content: '';
      position: absolute;

      width: 10px;
      height: 30px;

      top: calc(50% - 15px);
      right: -10px;

      background-image: url("/images/x-timer-icon.svg");
      background-repeat: no-repeat;
    }
  }

  @include media-breakpoint-up(lg){
    padding: 27px 40px 26px 60px;

    &::after{
      right: 0;
    }
  }
}

.timer__item {
  text-align: center;
}

.timer__item:nth-child(n + 5){
  display: none;
  @include media-breakpoint-up(md){
    display: block;
  }
}

.timer__item div {
  font-size: 3.6rem;
  line-height: 1em;
  font-weight: $fontBlack;
}

.timer__item span {
  font-size: 1.4rem;
  font-weight: $fontBold;
  text-transform: uppercase;
  color: $colorGrey;
}

.timer__button {
  position: absolute;

  @include media-breakpoint-up(md){
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    left: auto;
  }
}

