.article {
  max-width: 1110px;
  margin: 0 auto;
  padding: 90px 20px 40px;

  @include media-breakpoint-up(md) {
    padding-top: 120px;
  }

  @include media-breakpoint-up(lg) {
    padding: 130px 40px 60px;
  }

  @media (min-width: 1920px){
    max-width: 1400px;
  }

  p, li {
    font-size: 1.4rem;

    @include media-breakpoint-up(lg){
      font-size: 1.6rem;
    }
  }

  ul {
    margin: 16px;
    list-style: disc;
  }

  a {
    color: $blue;
  }

  p + p {
    margin-top: 10px;
  }

  h1 {
    margin: 16px 0;
  }
}