.coc {
  max-width: 1110px;
  padding: 60px 20px 60px;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    padding: 150px 20px;
  }

  @media (min-width: 1920px){
    max-width: 1300px;
  }
}

.coc__title-wrapper {
  display: flex;
  position: relative;
  width: 240px;
  height: 46px;
  padding-top: 15px;
  margin:0 auto 40px;

  justify-content: center;

  @include media-breakpoint-up(lg) {
    width: 500px;
    height: 96px;
    padding-top: 30px;
    margin: 0;
    margin-bottom: 20px;
  }
}

.coc__title-wrapper img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: auto;
}

.coc__title {
  position: relative;
  margin: 0;
  font-size: 1.8rem;
  font-weight: $fontBlack;
  text-align: center;
  color: $colorWhite;
  text-transform: uppercase;

  z-index: 1;

  @include media-breakpoint-up(md){
    font-size: 2rem;
  }

  @include media-breakpoint-up(lg){
    font-size: 3.6rem;
  }
}

.coc__wrapper {
  @include media-breakpoint-up(sm){
    display: flex;
    justify-content: space-between;
  }
}

.coc__image-wrapper {
  position: relative;
  margin: 0 auto 30px;
  padding-top: 36px;
  padding-right: 36px;

  @include media-breakpoint-up(sm){
    margin: 0;
    width: 50%;
    max-width: 410px;

    order: 1;
  }

  @include media-breakpoint-up(md) {
    padding-top: 40px;
    padding-right: 40px;
  }
}

.coc__background-image {
  position: absolute;
  width: 80%;
  height: 85%;
  top: 0;
  right: 0;
  overflow: hidden;

  z-index: 0;
  @include media-breakpoint-up(sm) {
    height: 55%;
  }

  @include media-breakpoint-up(md) {
    height: 80%;
  }
  @include media-breakpoint-up(lg) {
    height: 80%;
  }
}
 
.coc__picture {
  position: relative;
  width: 100%;

  height: 0;
  padding-right: 20px;
  padding-bottom: 60%;

  overflow: hidden;

  z-index: 1;
  @include media-breakpoint-up(md){
    max-width: 360px;
    padding-bottom: 100%;
    
  }
  @include media-breakpoint-up(lg) {
    height: 450px;
  }
}
.coc__picture img{
  position: absolute;
  bottom: -50px;
  left: 0;

  @include media-breakpoint-up(lg){
    bottom: 0;
  }
}

.coc__text-wrapper {
  @include media-breakpoint-up(sm){
    width: 48%;

    order: 0;
  }

  @include media-breakpoint-up(lg){
    padding-top: 50px;
    padding-left: 100px;
  }
}

.coc__description {
  margin-bottom: 30px;

  font-size: 1.6rem;

  @include media-breakpoint-up(lg){
    font-size: 1.8rem;
  }
}

.coc__link {
  position: relative;
  font-size: 1.3rem;
  font-weight: $fontBold;
  color: $colorRed;
  line-height: 1.1em;
  text-transform: uppercase;

  transition: color 0.3s;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    right: -20px;
    height: 7px;
    width: 7px;
    border-style: solid;
    border-color: $colorRed;
    border-width: 0px 2px 2px 0px;
    transform: rotate(-45deg);
    transition: right 0.3s;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }
}

.coc__link:hover {
  color: $colorBlack;
}

.coc__link:hover::before{
  right: -24px;
}