.footer {
  max-width: 320px;
  padding: 0 20px 20px;
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 1920px){
    max-width: 1360px;
  }
}

.footer__social {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    margin: 0;
    order: 1;
  }
}

.footer__copyright {
  font-size: 1.2rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    display: flex;
    font-size: 1.4rem;
  }

  @include media-breakpoint-up(lg) {
    order: 0;
  }
}


.footer__link {
  margin-right: 5px;
  color: $colorRed;

  transition: color 0.3s;
}

.footer__link:hover {
  color: $colorBlack;
}