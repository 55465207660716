.social {
  display: none;

  @include media-breakpoint-up(lg){
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.social__link .social__icon {
  transition: fill 0.5s;
}

.social__link:not(:last-child) {
  margin-right: 18px;

  @media only screen and (min-width: 992px) and (max-width: 1100px) {
    margin-right: 12px;
  }
}

.social__link:hover .social__icon {
  fill: $colorBlack;
}

.social__icon {
  fill: $colorRed;
}

.twitter-icon {
  width: 20px;
  height: 18px;
}

.instagram-icon {
  width: 18px;
  height: 18px;
}

.email-icon {
  width: 24px;
  height: 18px;
}