.news {
  max-width: 960px;
  padding: 0 20px 60px;
  margin: 0 auto;
  text-align: center;
}

.news__title-wrapper {
  display: flex;
  position: relative;
  width: 260px;
  height: 50px;
  padding-top: 15px;
  margin:0 auto 40px;

  justify-content: center;

  @include media-breakpoint-up(lg) {
    width: 470px;
    height: 97px;
    padding-top: 30px;
    margin: 0 auto 40px;
  }
}

.news__title-wrapper img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: auto;
}

.news__swiper-container {
  text-align: center;
}

.news__title {
  position: relative;
  margin: 0;
  font-size: 1.8rem;
  font-weight: $fontBlack;
  text-align: center;
  color: $colorWhite;
  text-transform: uppercase;

  z-index: 1;

  @include media-breakpoint-up(md){
    font-size: 2rem;
  }

  @include media-breakpoint-up(lg){
    font-size: 3.6rem;
  }
}

.news__list {
  margin:0 auto 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    max-width: 900px;
  }
}

.news__item {
  font-size: 1.6rem;
  font-weight: $fontRegular;
  text-align: left;
  margin-bottom: 40px;

  @include media-breakpoint-up(md) {
    max-width: 760px;
    margin: 0 auto 60px auto;
  }
  @include media-breakpoint-up(lg) {
    position: relative;

    font-size: 2.2rem;

    &::before{
      content: "";

      position: absolute;
      width: 16px;
      height: 36px;

      top: 0px;
      left: -76px;

      background-image: url("../../images/about-icon.svg");
      background-repeat: repeat;
    }
  }

  @media (min-width: 1920px){
    max-width: 960px;
  }


  .news__item__date {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: $fontRegular;

    @include media-breakpoint-up(lg) {
      font-size: 1.7rem;
    }
  }

  a {
    color: $colorRed;
    text-decoration: underline;

    &:hover {
      color: $colorBlack;
    }
  }
}


.news__link {
  position: relative;
  font-size: 1.3rem;
  font-weight: $fontBold;
  color: $colorRed;
  line-height: 1.1em;
  text-transform: uppercase;

  transition: color 0.3s;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    right: -20px;
    height: 7px;
    width: 7px;
    border-style: solid;
    border-color: $colorRed;
    border-width: 0px 2px 2px 0px;
    transform: rotate(-45deg);
    transition: right 0.3s;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }
}

.news__image {
  max-height: 110px;
  width: auto;
}

.type-partner .news__image {
  max-height: 160px;
}

.news__link:hover {
  color: $colorBlack;
}

.news__link:hover::before{
  right: -24px;
}

.news_group + .news_group {
  padding-top: 30px;
  box-shadow: 0 -1px 0 0 $colorLightGrey;
}

.news__group-title {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 40px;

  font-size: 1.6rem;
  line-height: 1.6em;
  font-weight: $fontBlack;
  text-transform: uppercase;

  &::before {
    content: "";

    position: absolute;

    width: 8px;
    height: 20px;
    left: 5px;
    top: calc(50% - 10px);

    transform: skew(-30deg);

    background-color: $colorRed;
  }

  @include media-breakpoint-up(lg){
    font-size: 2rem;
  }
}

.news__article {
  max-width: 1110px;
  margin: 0 auto;
  padding: 90px 20px 40px;

  @include media-breakpoint-up(md) {
    padding-top: 120px;
  }

  @include media-breakpoint-up(lg) {
    padding: 130px 40px 60px;
  }

  @media (min-width: 1920px){
    max-width: 1400px;
  }

  p, li {
    font-size: 1.4rem;

    @include media-breakpoint-up(lg){
      font-size: 1.6rem;
    }
  }

  ul {
    margin: 16px;
    list-style: disc;
  }

  a {
    color: $colorRed;
  }

  p + p {
    margin-top: 10px;
  }

  h1 {
    margin: 16px 0;
  }
}

.news__rss {
  margin-bottom: 40px;

  a {
    position: relative;
    font-size: 1.3rem;
    font-weight: $fontBold;
    color: $colorRed;
    line-height: 1.1em;
    text-transform: uppercase;

    transition: color 0.3s;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 3px);
      right: -20px;
      height: 7px;
      width: 7px;
      border-style: solid;
      border-color: $colorRed;
      border-width: 0px 2px 2px 0px;
      transform: rotate(-45deg);
      transition: right 0.3s;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }

    &:hover {
      color: $colorBlack;
    }
  }
}