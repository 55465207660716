.about {
  padding: 150px 20px 40px;

  @include media-breakpoint-up(md) {
    padding-top: 40px;
  }
  @include media-breakpoint-up(lg) {
    padding: 140px 0;
  }
}

.about__description{
  p {
    font-size: 1.6rem;
    font-weight: $fontRegular;
    text-align: center;
    padding-bottom: 20px;
  }

  @include media-breakpoint-up(md) {
    max-width: 760px;
    margin: 0 auto;
  }
  @include media-breakpoint-up(lg) {
    position: relative;

    p {
      font-size: 2.2rem;
    }

    &::before{
      content: "";

      position: absolute;
      width: 16px;
      height: 36px;

      top: -20px;
      left: -76px;

      background-image: url("../../images/about-icon.svg");
      background-repeat: repeat;
    }
  }

  @media (min-width: 1920px){
    max-width: 960px;
  }
}