.button {
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  min-height: 50px;
  min-width: 120px;
  justify-content: center;
  align-items: center;
  padding: 0 1em;

  font-weight: $fontBold;
  font-size: 1.6rem;
  line-height: 1em;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
}
 
.button--header {
  border: 4px solid $colorRed;

  transition: color 0.1s;

  @include media-breakpoint-up(lg){
    opacity: 1;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      transition: all 0.3s;
      width: 100%;
      height: 0;
      top: 50%;
      left: 50%;
      background: $colorRed;
      opacity: 0;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}

.header__button:hover {
  color: $colorWhite;
}

.header__button:hover::after {

  @include media-breakpoint-up(lg){
    height: 210%;
    opacity: 1;
  }
}

.header__button2:hover {
  color: $colorWhite;
}

.header__button2:hover::after {

  @include media-breakpoint-up(lg){
    height: 210%;
    opacity: 1;
  }
}

.button--black {
  position: relative;
  min-height: 60px;
  opacity: 1;
  white-space: nowrap;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);

  border: none;
  background-color: $colorBlack;
  color: $colorWhite;

  transition: background-color 0.2s;

  &::before{
    content: "";

    position: absolute;
    top: -3px;
    left: 13%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 14px 0 14px;
    border-color: $colorWhite transparent transparent transparent;
    z-index: 2;
  }

  &::after {
    content: "";

    position: absolute;
    bottom: -3px;
    left: 13%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 14px 8px 14px;
    border-color: transparent transparent $colorWhite transparent;
    z-index: 2;
  }

}

.button--black:hover{
  color: $colorWhite;
  background-color: $colorRed;
}

.button--red {
  width: 280px;
  min-height: 55px;
  padding-left: 30px;

  color: $colorWhite;

  background-color: $colorRed;
  transition: background-color 0.2s;

  &::after {
    content: "";

    position: absolute;
    width: 14px;
    height: 14px;
    top: calc(50% - 6px);
    left: 60px;

    background-image: url("../../images/button-arrow-icon.svg");
    background-repeat: no-repeat;
  }

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    padding-left: 30px;
    width: 190px;
    align-self: flex-end;
    
    &::after{
      left: 15px;
    }

  }
}

.button--red:hover{
  color: $colorWhite;
  background-color: $colorBlack;
}

.button--submit,.button--submit-white{
  padding-left: 40px;
  color: $colorWhite;
  border: none;
  position: absolute;
  padding: 0;
  width: 40px;
  height: 40px;
  min-height: auto;
  min-width: auto;
  right: 0;
  top: 40%;
  bottom: 40%;
  border-radius: 50%;
  background-color: $colorRed;
  font-size: 0;

  transition: transform 0.5s;

  &::before {
    content: "";

    position: absolute;
    border: solid $colorWhite;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;

    top: calc(50% - 5px);
    left: calc(50% - 8px);

    transform: rotate(-45deg);
  }
}

.button--submit-white {
  position: relative;
  background-color: $colorWhite;

  &::before {
    content: "";

    position: absolute;
    border: solid $colorBlack;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;

    top: calc(50% - 5px);
    left: calc(50% - 8px);

    transform: rotate(-45deg);
  }
}

.button--submit:disabled {
  background-color: $colorGrey;
}

.button--submit:disabled:hover {
  background-color: $colorGrey;
  transform: scale(1);
}

.button--submit:hover{

  @include media-breakpoint-up(md){

    transform: scale(0.93);
  }
}