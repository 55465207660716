.header {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: $colorWhite;

  @include media-breakpoint-up(lg){
    z-index: 10;

    &::before{
      display: none;
    }
  }
}

.header__container {
  max-width: 1240px;
  padding: 20px;
  margin: 0 auto;
  text-align: center;

  @include media-breakpoint-up(lg){
    display:  flex;
    padding: 25px 40px;
    justify-content: space-between;
    align-items: center;
  }

  @include media-breakpoint-down(md){
    max-height: 100vh;
    overflow: auto;
  }

  @media (min-width: 1920px){
    max-width: 1400px;
  }
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.header__logo img {
  width: 150px;
  height: 36px;

  @include media-breakpoint-up(lg){
    width: 200px;
    height: 46px;
  }
}

.header__hamburger {
  width: 20px;
  height: 16px;
  position: relative;
  cursor: pointer;

  @include media-breakpoint-up(lg){
    display: none;
  }
}

.header__hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  top:calc(50% - 3px);
  box-shadow: 0 2px 0 0 $colorBlack;
  transition:all .4s ease-in-out;
}

.header__hamburger:before,
.header__hamburger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  box-shadow: 0 2px 0 0 black;
  transition:all .4s ease-in-out;
}

.header__hamburger::before {
  top:-2px;
}

.header__hamburger::after {
  bottom:2px;
}

.header--open {
  @include media-breakpoint-down(md) {
    min-height: 100vh;
  }
}

.header--open .header__hamburger span {
  transform: scaleX(0);
  opacity: 0;
}

.header--open .header__hamburger:before {
  top: calc(50% - 2px);
  transform: rotate(-45deg);
}

 .header--open .header__hamburger::after {
  left: 3px;
  top: calc(50% - 2px);
  transform: rotate(45deg);
}

.header__button {
  position: absolute;
  left: -100%;

  @include media-breakpoint-up(lg){
    position: relative;
    left: 0;
    margin-left: 30px;
  }

  @media only screen and (min-width: 992px) and (max-width: 1100px) {
    margin-left: 20px;
  }
}

.header__button2 {
  position: relative;
  margin-top: 10px;
}

.header--open .header__button {
  @include media-breakpoint-down(md) {
    width: 280px;
    position: static;
    margin-left: 0;
    opacity: 1;
  }

  @media only screen and (min-width: 320px) and (max-width: 991px) and (orientation: landscape) {
    margin-top: 30px;
  }
}