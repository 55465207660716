.menu {
  display: none;
  width: 100%;

  background-color: $colorWhite;
  

  @include media-breakpoint-up(lg) {
    width: 55%;
    display: block;
    height: auto;
    margin-left: auto;
  }
}

.header--open .menu {
  @include media-breakpoint-down(md) {
    display: block;
    height: auto;
  }
}

.menu__list {
  display: flex;
  flex-direction: column;
  padding-top: 20vh;

  text-align: center;

  @include media-breakpoint-up(lg) {
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  @media only screen and (min-width: 320px) and (max-width: 991px) and (orientation: landscape) {
    padding-top: 8vh;
  }
}

.menu__item {
  margin-bottom: 30px;

  font-weight: $fontBlack;

  white-space: nowrap;

  @include media-breakpoint-up(lg) {
    width: auto;
    margin-bottom: 0;

    &--mobile {
      display: none;
    }
  }

  @include media-breakpoint-up(xl){
    margin-right: 40px;
  }

  @media only screen and (min-width: 320px) and (max-width: 991px) and (orientation: landscape) {
    margin-bottom: 8px;
  }
}

.menu__item:last-child {
  margin-right: 0;
}

.menu__link {
  font-weight: $fontBlack;
  font-size: 2rem;
  line-height: 1.5em;


  @include media-breakpoint-up(lg) {
    position: relative;
    padding: 5px;

    font-size: 1.6rem;
    font-weight: $fontRegular;

    &::after {
      content: '';
      position: absolute;
      width: 100%; 
      height: 2px;
      display: block;
      left: 0;
      right: 0;
      top: calc(50% + 1px);
      background: $colorRed;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .2s ease;
    }
    
    &:hover::after{
      transform: scaleX(1);
    }

    &:hover {
      text-decoration: none;
    }
  }
}
