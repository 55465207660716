.venue {
  max-width: 1200px;
  padding: 0 20px 50px;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    position: relative;
    padding-top: 70px;
    padding-bottom: 90px;

    &::after {
      content: "}";

      position: absolute;

      top: 0;
      right: 140px;
      font-size: 4rem;
      line-height: 1em;
      font-weight: $fontBlack;
    }
  }

  @media (min-width: 1920px){
    max-width: 1400px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.venue__title {
  position: relative;
  width: 210px;
  margin: 0 auto 50px;
  font-size: 3.2rem;
  font-weight: $fontExtralight;

  text-align: center;

  &::after {
    content: "";

    position: absolute;
    width: 6px;
    height: 22px;
    left: 0px;
    top: calc(50% - 11px);
    
    transform: skew(-30deg);
    background: $colorRed;
  }

  @include media-breakpoint-up(md){
    margin: 0;
    margin-bottom: 30px;
  }

  @include media-breakpoint-up(lg){
    width: 320px;

    font-size: 4.8rem;

    &::after {
      
      height: 30px;

      top: calc(50% - 15px);
    }
  }
}

.venue__place {
  width: 210px;
  margin: 0 auto 30px;
  @include media-breakpoint-up(md) {
    width: 100%;
  }
  
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: flex-end;
    margin: 0;
  }
}

.venue__place img {
  margin-right: 20px;
  width: 90px;
  height: 90px;

  @include media-breakpoint-up(md){
    margin-bottom: 20px;
  }
  @include media-breakpoint-up(lg){
    margin-bottom: 0;
  }
}

.venue__place p {
  display: inline-block;
  width: 90px;
  font-size: 1.6rem;
  font-weight: $fontBold;

  vertical-align: middle;
  
}

.venue__button--desktop {
  display: none;
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.venue__button--mobile {
  display: flex;
  margin: 0 auto 45px;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.venue__image-wrapper {
  position: relative;
  width: 100%;
  max-width: 660px;
  margin:0 auto 30px;
  @include media-breakpoint-up(md) {
    margin: 0;
    margin-top: 10px;
    width: 60%;
  }
}

.venue__image {
  position: absolute;
}

.venue__image--first {
  width: 35%;

  left: 0;
  top: 0;
}

.venue__image--second {
  position: relative;
  display: block;
  margin-left: auto;
  padding-top: 14%;
  padding-bottom: 25%;
  width: 55%;
  max-width: 360px;

  z-index: 2;
}

.venue__image--third {
  width: 55%;

  bottom: 0;
  left: 20%;
}

.venue__map-wrapper {
  position: relative;
  margin:0 auto 30px;
  max-width: 460px;

  @include media-breakpoint-up(md) {
    width: 40%;
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    &::before {
      width: 30px;
      height: 30px;
      left: 40%;
      top: 40%;

      background-image: url("../../images/map-pin-desktop.svg");
    }
  }
}

.venue__adress {
  position: absolute;
  width: 170px;
  padding: 10px 15px 10px 10px;

  top: 44%;
  left: 21%;

  font-size: 1.3rem;
  line-height: 1.2em;

  background-color: $colorWhite;
  border-radius: 10px;

  &::after {
    content: "";

    position: absolute;

    bottom: calc(100% - 1px);
    left: 45px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 10px 15px;
    border-color: transparent transparent $colorWhite transparent;
  }

  &::before {
      content: "";

      position: absolute;
      width: 14px;
      height: 14px;
      left: 32%;
      top: -40%;

      background-image: url("../../images/map-pin.svg");
      background-repeat: no-repeat;
    }
  

  @include media-breakpoint-up(lg) {
    width: 360px;
    padding: 12px 20px;
    font-size: 1.8rem;

    top: 20%;
    left: 54%;

    box-shadow: 0px 25px 30px rgba(207, 78, 78, 0.15);
    border-radius: 15px;


    &::after {
      bottom: 0;
      left: -25px;

      border-width: 0 0 20px 40px;
      box-shadow: 0px 25px 30px rgba(207, 78, 78, 0.15);
    }

    &::before {
      width: 30px;
      height: 30px;
      left: -18%;
      top: 85%;

      background-image: url("../../images/map-pin-desktop.svg");
  }

  }
}

.venue__map-wrapper span {
  @include media-breakpoint-up(lg){
    display: block;
  }
}

.venue__form {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    position: relative;
    margin-left: auto;
    width: 50%;
    max-width: 460px;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 9vw;
  }
}

.venue__form label {
  margin-bottom: 15px;
  font-size: 1.6rem;
  font-weight: $fontBold;

  text-transform: uppercase;
  @include media-breakpoint-up(md){
    margin-bottom: 30px;
  }
}

.venue__form input {
  margin-bottom: 30px;
  padding: 5px 0 10px;

  font-size: 1.6rem;

  border: none;
  border-bottom: 2px solid $colorBlack;

  outline: none;

  @include media-breakpoint-up(md){
    padding-right: 45px;
    margin-bottom: 20px;
  }
}

.venue__form input:focus {
  border-color: $colorRed;
}

.venue__form input::placeholder {
  font-size: 1.6rem;
  font-weight: $fontLight;
}

.venue__form--button {
  margin: 0 auto;

  @include media-breakpoint-up(md){
    position: absolute;
  }
}

.venue__form--button .disable {
  background-color: $colorGrey;
}

.venue__wrapper {
  @include media-breakpoint-up(md) {
    display: flex;

    &:first-child {
      justify-content: space-between;
      align-items: center;
    }

    &:last-child {
      align-items:flex-end;
    }
  }

  @include media-breakpoint-up(lg) {
    &:first-child {
      margin-bottom: -40px;
    }
  }
}

.venue__wrapper-text {
  @include media-breakpoint-up(md) {
    width: 40%;
  }
}

.venue__form__result {
  margin-bottom: 20px;

  font-size: 1.2rem;

  @include media-breakpoint-up(md) {
    position: absolute;

    left: 0;
    top: 100%;
  }
}

.response {
  position: absolute;
  font-size: 1.4rem;
  @include media-breakpoint-down(sm){
    position: relative;
    margin-bottom: 10px;
  }
}
.response-success {
  color: #28a745;
}
.response-error {
  color: #FF0000;
}