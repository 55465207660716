.tickets {
  padding: 80px 20px;

  background-color: $backgroundColorGrey;

  @include media-breakpoint-up(lg) {
    padding-bottom: 50px; 
  }
}

.tickets__container{
  max-width: 960px;
  margin: 0 auto;

  text-align: center;
}

.tickets__title {
  position: relative;
  margin-bottom: 90px;

  @include media-breakpoint-down(sm){
    margin-bottom: 50px;
  }

  font-size: 3.2rem;
  font-weight: $fontExtralight;
  text-align: center;

  &::before {
    content: "";

    position: absolute;

    width: 7px;
    height: 25px;
    left: calc(50% - 95px);
    top: calc(50% - 12px);

    transform: skew(-30deg);

    background-color: $colorRed;
  } 

  @include media-breakpoint-up(lg) {
    font-size: 4.8rem;

    &::before {
      width: 7px;
      height: 30px;
      left: calc(50% - 130px);
      top: calc(50% - 15px);
    }
  }
}

.tickets__card {
  position: relative;
  display: flex;
  padding: 20px;  

  justify-content: space-between;

  font-size: 1.6rem;
  line-height: 1.3em;
  text-align: center;

  background-color: $colorWhite;
  box-shadow: 0px 15px 20px rgba(207, 78, 78, 0.15);

  &:first-of-type {
    margin-bottom: 100px;
  }

  &::before {
    content: '';

    position: absolute;
    height: 5px;
    width: 100%;
    top: 0;
    left: 0;

    background-color: $colorRed;
  }

  @include media-breakpoint-down(sm){
    display: none;
  }

  @include media-breakpoint-up(md) {
    &:first-of-type {
      margin-bottom: 20px;
    }
  }
  @include media-breakpoint-up(lg){
    padding: 42px 115px 38px 30px;
  }
}

.tickets__name {
  position: absolute;
  top: -40px;
  left: 0;;

  font-size: 1.6rem;
  font-weight: $fontBold;

  @include media-breakpoint-up(md) {
    position: relative;
    top: 0;
    width: 40%;

    text-align: left;
  }

  @include media-breakpoint-up(lg){
    font-size: 2rem;
  }
}

.tickets__price {
  font-size: 2rem;

  @include media-breakpoint-up(md){
    position: relative;
  }

  @include media-breakpoint-up(lg){
    font-size: 1.8rem;
  }
}

.tickets__price span {
  display: block;
  margin-top: 10px;

  font-size: 1.4rem;
  font-weight: $fontBold;
  color: $colorGrey;

  text-transform: uppercase;

  @include media-breakpoint-up(md){
    margin: 0;
    position: absolute;

    top: -70px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;

    top: -90px;
  }
}

.tickets__price:nth-child(2) span{
  @include media-breakpoint-up(md){
    left: calc(50% - 26px);
  }
}

.tickets__price:nth-child(3) span{
  @include media-breakpoint-up(md){
    left: calc(50% - 43px);
  }
}

.tickets__price:nth-child(4) span{
  @include media-breakpoint-up(md){
    left: calc(50% - 22px);
  }
}


.tickets__card:last-child span {
 
  @include media-breakpoint-up(md){
    display: none;
  }
}