html {
  font-size: 10px;
  overflow-x: hidden;
  height: 100%;
}
body {
  height: auto;
  margin: 0;
  padding: 0;
  font-family: $fontCode;
  font-weight: $fontRegular;
  color: $colorBlack;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;html {
    font-size: 10px;
    overflow-x: hidden;
    height: 100%;
  }
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: $fontCode;
    font-weight: $fontRegular;
    color: $colorBlack;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  
  button {
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  
  p {
    margin-bottom: 0;
  }
  
  .main {
    height: 100%;
  }
  
  .container {
    width: 100%;
    min-width: 280px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0px 20px;
  }
  
  a {
    color: $colorBlack;
  }
  
  a:hover {
    color: $colorBlack;
    text-decoration: none;
  }
  list-style: none;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

button {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

p {
  margin-bottom: 0;
}

.main {
  height: 100%;
}

.container {
  width: 100%;
  min-width: 280px;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 20px;
}

a {
  color: $colorBlack;
}

a:hover {
  color: $colorBlack;
  text-decoration: none;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
