.video-content {
    max-width: 1070px;
    padding: 0 40px 115px;
    margin: 0 auto;
  }

.video-content__wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; 
    height: 0; 
    overflow: hidden;
}

.video-content__wrapper iframe,
.video-content__wrapper object,
.video-content__wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}