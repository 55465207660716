$colorBlack: #000;
$colorWhite: #fff;
$colorRed:#FF0000;
$colorGrey: #C4C4C4;
$colorLightGrey: #EAEAEA;
$backgroundColorGrey: #F4F5F7;

$fontCode: 'Source Code Pro', monospace;

$fontExtralight:200;
$fontLight: 300;
$fontRegular: 400;
$fontMedium: 500;
$fontSemibold: 600;
$fontBold: 700;
$fontBlack: 900;