.hero {
  min-width: 280px;
  max-width: 1240px;
  padding: 100px 20px 0;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding-top: 120px;
    position: relative;
  }

  @include media-breakpoint-up(lg) {
    margin: 0 auto;
    padding: 130px 40px 0;
  }

  @media (min-width: 1920px){
    max-width: 1400px;
  }
}

.hero__wrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 320px) and (orientation: landscape) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (min-width: 450px){
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

}

.hero__wrapper div {
  @media screen and (min-width: 320px) and (orientation: landscape) {
    width: 50%;
  }
  @media screen and (min-width: 450px){
    width: 50%;
  }
}

.hero__wrapper .hero__wrapper-inner {
  @media screen and (min-width: 320px) and (orientation: landscape) {
    width: 50%;
  }
  @media screen and (min-width: 450px){
    width: 50%;
  }
  @include media-breakpoint-up(lg) {
    width: 60%;
  }
}

.hero__data {
  font-size: 1.6rem;
  font-weight: $fontBlack;

  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
  }
  @include media-breakpoint-up(lg){

    font-size: 2.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3rem;
  }
}

.hero__title {
  margin: 0;
  margin-left: -2px;
  font-size: 2.4rem;
  font-weight: $fontLight;
  max-width: 300px;

  @include media-breakpoint-up(md) {
    margin-left: -3px;
    font-size: 3.8rem;
    max-width: 420px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 600px;
    margin-bottom: 20px;
    margin-left: -5px;

    font-size: 5rem;
    font-weight: $fontExtralight;
  }
  @include media-breakpoint-up(xl) {
    font-size: 5.5rem;
  }
}

.hero__hashtag {
  margin-bottom: 40px;

  font-size: 1.2rem;
  font-weight: $fontBlack;
  color: $colorRed;

  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
    margin-bottom: 40px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 60px;
  }
}

.hero__adress {
  position: relative;
  padding-left: 50px;
  margin-bottom: 30px;

  font-size: 1.2rem;
  line-height: 1.5em;
  font-weight: $fontRegular;

  &:before {
    content: '';

    position: absolute;
    width: 40px;
    height: 28px;
    left: 0;
    top: calc(50% - 14px);

    background-image: url("../../images/way-icon-mobile.svg");
    background-repeat: no-repeat;
  }


  @include media-breakpoint-up(md) {
    max-width: 350px;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 100px;
    max-width: 560px;
    margin-bottom: 10px;

    font-size: 1.6rem;

    &:before{
      width: 71px;
      height: 58px;

      top: 0;

      background-image: url("../../images/way-icon.svg");
    }
  }
}

.hero__adress--desktop {
  display: none;

  @media screen and (min-width: 320px) and (orientation: landscape) {
    display: block;
  }
  @media screen and (min-width: 570px){
    display: block;
  }
}

.hero__adress--mobile {
  display: none;
  @media (orientation: portrait) {
    display: block;
  }
  @media screen and (min-width: 570px){
    display: none;
  }
}

.hero__image-wrapper {
  position: relative;
  width: 100%;
  margin:0 auto 30px;
  padding-left: 20px;
  padding-bottom: 20px;
  max-width: 400px;

  box-sizing: border-box;

  @include media-breakpoint-up(md) {
    width: 40%;
    margin: 0;
    margin-left: 20px;
    padding-left: 40px;
    padding-bottom: 30px;

  }

  @include media-breakpoint-up(lg){
    max-width: 470px;
  }
}

.hero__image {
  position: relative;

  top: 0;
  right: 0;
  z-index: 1;

}

.hero__background-dots {
  position: absolute;

  width: 90%;
  max-width: 430px;
  height: auto;
  max-height: 420px;

  left: 0;
  bottom: 0;
  
  z-index: 0;
}

.hero__background-x {
  position: absolute;

  width: 65%;
  max-width: 300px;
  height: auto;


  left: 0;
  top: 6%;
  
  z-index: 2;
}
