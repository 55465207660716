.participation {
  max-width: 1100px;
  padding: 0 20px 60px;
  margin: 0 auto;
  @include media-breakpoint-up(md){
    padding-bottom: 150px;
  }

  @media (min-width: 1920px){
    max-width: 1300px;
    padding-left: 42px;
  }
}

.participation__title-wrapper {
  display: flex;
  position: relative;
  width: 280px;
  height: 50px;
  padding-top: 15px;
  margin:0 auto 40px;

  justify-content: center;

  @include media-breakpoint-up(md){
    width: 300px;
    height: 60px;
  }

  @include media-breakpoint-up(lg) {
    width: 581px;
    height: 96px;
    padding-top: 30px;
    margin: 0;
    margin-left: auto;
    margin-bottom: 20px;
  }
}

.participation__title-wrapper img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: auto;
}

.participation__title {
  position: relative;
  margin: 0;
  font-size: 1.8rem;
  font-weight: $fontBlack;
  text-align: center;
  color: $colorWhite;
  text-transform: uppercase;

  z-index: 1;

  @include media-breakpoint-up(md){
    font-size: 2rem;
  }

  @include media-breakpoint-up(lg){
    font-size: 3.6rem;
  }
}

.participation__wrapper {
  @include media-breakpoint-up(sm){
    display: flex;
    justify-content: space-between;
  }
}

.participation__image-wrapper {
  position: relative;
  margin: 0 auto 30px;
  padding-top: 30px;
  padding-left: 30px;

  @include media-breakpoint-up(sm){
    margin: 0;
    width: 50%;
    max-width: 410px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 40px;
    padding-left: 40px;
  }
}

.participation__background-image {
  position: absolute;
  width: 80%;
  height: 85%;
  top: 0;
  left: 0;
  overflow: hidden;

  z-index: 0;
  @include media-breakpoint-up(sm) {
    height: 100%;
    max-height: 165px;
  }

  @include media-breakpoint-up(md) {
    height: 80%;
    max-height: 385px;
  }
  @include media-breakpoint-up(lg) {
    height: 80%;
  }
}
 
.participation__picture {
  position: relative;
  width: 100%;

  height: 0;
  padding-right: 20px;
  padding-bottom: 60%;

  overflow: hidden;

  z-index: 1;
  @include media-breakpoint-up(sm){
    max-width: 360px;
    padding-bottom: 100%;
    
  }
  @include media-breakpoint-up(lg) {
    height: 450px;
  }
}
.participation__picture img{
  position: absolute;
  bottom: -50px;
  left: 0;

  @include media-breakpoint-up(lg){
    bottom: 0;
  }
}

.participation__text-wrapper {
  @include media-breakpoint-up(sm){
    width: 48%;
  }

  @include media-breakpoint-up(lg){
    padding-top: 50px;
    padding-right: 60px;
  }
}

.participation__description {
  margin-bottom: 30px;

  font-size: 1.6rem;

  @include media-breakpoint-up(lg){
    margin-bottom: 55px;
    font-size: 1.8rem;
  }
}

.participation__start-day,
.participation__end-day {
  position: relative;
  padding-left: 25px;
  font-size: 1.2rem;
  font-weight: $fontBlack;
  line-height: 1.5em;
  text-transform: uppercase;

  &::after{
    content: "";

    position: absolute;
    width: 6px;
    height: 14px;
    left: 5px;
    top: calc(50% - 7px);

    transform: skew(-30deg);

    background-color: $colorRed;
  }

  @include media-breakpoint-up(lg){
    font-size: 2rem;
    padding-left: 40px;

    &::after{
      content: "";
  
      position: absolute;
      width: 8px;
      height: 22px;
      left: 5px;
      top: calc(50% - 11px);
  
      transform: skew(-30deg);
  
      background-color: $colorRed;
    }
  }
}

.participation__start-day {
  margin-bottom: 5px;
}

.participation__end-day {
  margin-bottom: 15px;

  @include media-breakpoint-up(lg){
    margin-bottom: 30px;
  }
}

.participation__footnote {
  margin-bottom: 30px;
  font-size: 1rem;

  @include media-breakpoint-up(lg){
    margin-bottom: 40px;
    font-size: 1.6rem;
  }
}

.participation__list {
  margin-bottom: 20px;
}

.participation__item {
  position: relative;
  margin-bottom: 10px;
  padding-left: 28px;

  font-size: 1.6rem;
  font-weight: $fontRegular;

  &::before {
    content: "";

    position: absolute;
    width: 11px;
    height: 11px;
    left: 0;
    top: calc(50% - 5px);

    background-image: url("../../images/list-icon.svg");
    background-repeat: no-repeat;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg){
    padding-left: 50px;
    font-size: 1.8rem;
  }
}

.participation__link {
  position: relative;
  font-size: 1.3rem;
  font-weight: $fontBold;
  color: $colorRed;
  line-height: 1.1em;
  text-transform: uppercase;
  transition: color 0.3s;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    right: -20px;
    height: 7px;
    width: 7px;
    border-style: solid;
    border-color: $colorRed;
    border-width: 0px 2px 2px 0px;
    transform: rotate(-45deg);
    transition: right 0.3s;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.general__link {
  color: $colorRed;

  transition: color 0.3s;

  &:hover {
    color: $colorBlack;
  }
}